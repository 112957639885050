import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Customers from "./pages/customers";
// import Profile from "./pages/profile";
import Channels from "./pages/channels";
import ErrorNotFound from "./pages/error";
import Dashboard from "./pages/dashboard";
import CustomerById from "./pages/customer-by-id";
import ChannelById from "./pages/channel-by-id";
import ChannelByIdEdit from "./pages/channel-by-id-edit";
// import TableauDash from "./pages/tableau";
import Platforms from "./pages/platforms";
import PlatformById from "./pages/platform-by-id";
import PlatformByIdEdit from "./pages/platform-by-id-edit";
import DashboardTickets from "./pages/dashboard-tickets";
import ChannelDeliveries from "./pages/channel-deliveries";
import VODDeliveries from "./pages/vod-deliveries";
import LiveDeliveries from "./pages/live-deliveries";
import Playouts from "./pages/playouts";
import ChannelDeliveryById from "./pages/channel-delivery-by-id";
import ChannelDeliveryByIdEdit from "./pages/channel-delivery-by-id-edit";
import VODDeliveryById from "./pages/vod-delivery-by-id";
import VODDeliveryByIdEdit from "./pages/vod-delivery-by-id-edit";
import LiveDeliveryById from "./pages/live-delivery-by-id";
import LiveDeliveryByIdEdit from "./pages/live-delivery-by-id-edit";
import PlayoutDeliveryById from "./pages/playout-by-id";
import PlayoutDeliveryByIdEdit from "./pages/playout-by-id-edit";
import GroupedPlatforms from "./pages/grouped-platforms";
import GroupedChannels from "./pages/grouped-channels";
import CommonChannels from "./pages/common-channels";
import SalesView from "./components/customer-page/sales-view";
import Reports from "./pages/reports/reports";
import SKUReports from "./pages/reports/product_sku_reports";
import { useGetAmagiIdsForUserQuery } from "./hasura.graphql";
import { Auth } from "aws-amplify";
// import CustomerTransactionById from "./pages/customer-transaction-by-id";
// import UserguideAndFAQ from "./pages/userguide-and-faq";
// import UserguideAndFAQEdit from "./pages/userguide-and-faq-edit";

const channelRoutes = [
  {
    url: "redundant",
    type: "Redundant",
    header: "Redundant Channels",
  },
  {
    url: "subfeed",
    type: "Subfeed",
    header: "Subfeed Channels",
  },
  {
    url: "test",
    type: "Test",
    header: "Test Channels",
  },
  {
    url: "vod",
    type: "VOD",
    header: "VOD Channels",
  },
  {
    url: "pop-up",
    type: "Pop-up",
    header: "Pop-up Channels",
  },
];

const channelByIDRoutes = [
  {
    url: "linear",
    header: "All Linear Channels",
  },
  {
    url: "grouped",
    header: "All Grouped Channels",
  },
  {
    url: "redundant",
    header: "All Redundant Channels",
  },
  {
    url: "subfeed",
    header: "All Subfeed Channels",
  },
  {
    url: "test",
    header: "All Test Channels",
  },
  {
    url: "vod",
    header: "All VOD Channels",
  },
  {
    url: "pop-up",
    header: "All Pop-up Channels",
  },
];

export const customerRoutes = [
  { param: "details", label: "Details" },
  { param: "overview", label: "Overview" },
  { param: "contract-details", label: "Contract Details" },
  { param: "opportunities", label: "Opportunities" },
  { param: "quotes", label: "Quotes" },
  { param: "orders", label: "Orders" },
  { param: "invoices", label: "Financials" },
  { param: "credit-memos", label: "Financials" },
  { param: "receipts", label: "Financials" },
  { param: "financial-statements", label: "Financials" },
  { param: "products", label: "Products" },
  { param: "channels", label: "Channels" },
  { param: "deliveries", label: "Deliveries" },
  { param: "service-tickets", label: "Service Tickets" },
  { param: "contacts", label: "Contacts" },
];

export const AppRoutes = () => {
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const email = user.attributes.email;
        setUserEmail(email);
        console.log("Logged-in user's email:", email);
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    fetchUserEmail();
  }, []);

  const amgIdList = useGetAmagiIdsForUserQuery({
    variables: { user_email: userEmail },
    skip: !userEmail, 
  });

  const amagiIds = amgIdList.data?.financial_permissions.map(
    (item) => item.amagi_id
  );



  return (
    <Routes>
      <Route path="*" element={<ErrorNotFound />} />
      <Route path="/" element={<Navigate to={"/customers"} />} />
      <Route path="/dashboards" element={<Dashboard />} />
      {amagiIds?.includes("all") && (
        <Route path="/reports/invoice-lines" element={<Reports />} />
      )}
      <Route path="/reports/product-skus" element={<SKUReports />} />
      <Route path="/reports" element={<Navigate to={"/reports/product-skus"} />} />
      <Route path="/dashboards/tickets" element={<DashboardTickets />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/customers/:id" element={<Navigate to={"/customers/:id/details"} />} />
      {customerRoutes.map((route, index) => (
        <Route
          key={index}
          path={`/customers/:id/${route.param}`}
          element={<CustomerById param={route.label} />}
        />
      ))}
      {/* Channel */}
      <Route path="/channels" element={<Navigate to={"/channels/linear"} />} />
      <Route path="/platforms" element={<Navigate to={"/platforms/platform"} />} />
      <Route path="/deliveries" element={<Navigate to={"/deliveries/channel-deliveries"} />} />
      <Route path="/channels/linear" element={<Channels />} />
      <Route path="/channels/grouped" element={<GroupedChannels />} />
      {channelRoutes.map((route, index) => (
        <Route
          key={index}
          path={`/channels/${route.url}`}
          element={<CommonChannels header={route.header} channelType={route.type} url={route.url} />}
        />
      ))}
      {/* Channel By ID */}
      {channelByIDRoutes.map((route, index) => (
        <Route
          key={index}
          path={`/channels/${route.url}/:id`}
          element={<ChannelById header={route.header} url={`/channels/${route.url}`} />}
        />
      ))}
      {/* Channel By ID Edit */}
      {channelByIDRoutes.map((route, index) => (
        <Route
          key={index}
          path={`/channels/${route.url}/:id/edit`}
          element={<ChannelByIdEdit header={route.header} url={`/channels/${route.url}`} />}
        />
      ))}
      {/* Platform */}
      <Route path="/platforms/platform" element={<Platforms />} />
      <Route path="/platforms/grouped-platform" element={<GroupedPlatforms />} />
      {/* Platform By ID */}
      <Route path="/platforms/platform/:id" element={<PlatformById header="All Platforms" url="/platforms/platform" />} />
      <Route path="/platforms/grouped-platform/:id" element={<PlatformById header="All Grouped Platforms" url="/platforms/grouped-platform" />} />
      {/* Platform By ID Edit */}
      <Route path="/platforms/platform/:id/edit" element={<PlatformByIdEdit header="All Platforms" url="/platforms/platform" />} />
      <Route path="/platforms/grouped-platform/:id/edit" element={<PlatformByIdEdit header="All Grouped Platforms" url="/platforms/grouped-platform" />} />
      {/* Deliveries */}
      <Route path="/deliveries/channel-deliveries" element={<ChannelDeliveries />} />
      <Route path="/deliveries/vod-deliveries" element={<VODDeliveries />} />
      <Route path="/deliveries/live-deliveries" element={<LiveDeliveries />} />
      <Route path="/deliveries/playouts" element={<Playouts />} />
      {/* Deliveries By ID */}
      <Route path="/deliveries/channel-deliveries/:id" element={<ChannelDeliveryById />} />
      <Route path="/deliveries/vod-deliveries/:id" element={<VODDeliveryById />} />
      <Route path="/deliveries/live-deliveries/:id" element={<LiveDeliveryById />} />
      <Route path="/deliveries/playouts/:id" element={<PlayoutDeliveryById />} />
      {/* Deliveries By ID Edit */}
      <Route path="/deliveries/channel-deliveries/:id/edit" element={<ChannelDeliveryByIdEdit />} />
      <Route path="/deliveries/vod-deliveries/:id/edit" element={<VODDeliveryByIdEdit />} />
      <Route path="/deliveries/live-deliveries/:id/edit" element={<LiveDeliveryByIdEdit />} />
      <Route path="/deliveries/playouts/:id/edit" element={<PlayoutDeliveryByIdEdit />} />
      {/* View */}
      <Route path="/salesview" element={<SalesView />} />
    {/* <Route path="/tableau" element={<TableauDash />} /> */}
    {/* <Route path="/userguide-and-faq" element={<UserguideAndFAQ />} />
    <Route path="/userguide-and-faq/edit" element={<UserguideAndFAQEdit />} /> */}
    {/* <Route path="/profile" element={<Profile />} /> */}
    </Routes>
  );
};
