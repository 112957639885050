import {useEffect, useState } from "react";
import DataQuality from "../components/data-quality";
import DQOverview from "../components/dq-overview";
import Tabs from "../components/tabs";
import { useGetAmagiIdsForUserQuery } from "../hasura.graphql";
import { Auth } from "aws-amplify";
import DQFinancials from "../components/dq-financials";

const Dashboard: React.FC = () => {
  const [tab, setTab] = useState<string>("Overview");
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const email = user.attributes.email;
        setUserEmail(email);
        console.log("Logged-in user's email:", email);
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    fetchUserEmail();
  }, []);

  const amgIdList = useGetAmagiIdsForUserQuery({
    variables: { user_email: userEmail },
    skip: !userEmail, 
  });

  const amagiIds = amgIdList.data?.financial_permissions.map(
    (item) => item.amagi_id
  );

  
  const tabList = [
    { name: "Overview" },
    { name: "Data Quality" },
    amagiIds?.includes("all") && { name: "Financials" },
  ].filter(Boolean);
  return (
    <div style={{ backgroundColor: "#F5FAFE" }}>
      <div className="mb-4 sticky top-14 z-10">
        <Tabs currentTab={tab} tabList={tabList} toggleTab={setTab} />
      </div>
      <div className="mx-6">
        {tab === "Overview" && <DQOverview />}
        {tab === "Data Quality" && <DataQuality />}
        {tab === "Financials" && <DQFinancials />}
      </div>
    </div>
  );
};

export default Dashboard;