import React, {useEffect, useMemo, useState } from "react";
import { useFeatureFlagQuery, useProductSkuDataQuery, useGetAmagiIdsForUserQuery } from "../../hasura.graphql";
import Loader from "../../common/loader";
import { useCDPRole } from "../../cdp-role-context";
import PageTable from "../../common/page-table";
import ErrorBoundary from "../../components/error-boundary";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import TableRowsFilter from "../../common/filters/table-rows-filter";
import { Auth } from "aws-amplify";


const SKUReports: React.FC = () => {
  const { setCDPRole } = useCDPRole();
  const [deliverySearch, setDeliverySearch] = useState<string>("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const email = user.attributes.email;
        setUserEmail(email);
        console.log("Logged-in user's email:", email);
      } catch (error) {
        console.error("Error fetching user email:", error);
      }
    };

    fetchUserEmail();
  }, []);

  const amgIdList = useGetAmagiIdsForUserQuery({
    variables: { user_email: userEmail },
    skip: !userEmail, 
  });

  const amagiIds = amgIdList.data?.financial_permissions.map(
    (item) => item.amagi_id
  );

  
  const reportsTab = [
    ...(amagiIds?.includes("all") ? [
      {
        label: "Invoice Lines",
        url: "/reports/invoice-lines",
        active: pathname === "/reports/invoice-lines",
      }
    ] : []),
    {
      label: "Product/Services SKU",
      url: "/reports/product-skus",
      active: pathname === "/reports/product-skus",
    }
  ];

  const columns: any = useMemo(
    () => [
      {
        Header: "CODE",
        accessor: "code",
        show: true,
      },
      {
        Header: "NAME",
        accessor: "name",
        show: true,
      },
      {
        Header: "ACTIVE",
        accessor: "active",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "DEPRECATED",
        accessor: "deprecated",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "CHARGE TYPE",
        accessor: "charge_type",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "UNIT",
        accessor: "display_unit",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "USD",
        accessor: "usd",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },
      {
        Header: "GBP",
        accessor: "gbp",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },
      {
        Header: "EUR",
        accessor: "eur",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },
      {
        Header: "APAC USD ",
        accessor: "apac_usd",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },
      {
        Header: "APAC INR",
        accessor: "apac_inr",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },
      {
        Header: "QAR",
        accessor: "qar",
        show: true,
        Cell: ({ value }: { value: number }) => <div className="text-right">{value}</div>,
      },
      {
        Header: "BU",
        accessor: "bu",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "GROUP",
        accessor: "product_group",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SUB GROUP",
        accessor: "product_sub_group",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SUB GROUP CATEGORY",
        accessor: "product_sub_group_category",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SKU TYPE",
        accessor: "sku_type",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "SO DESCRIPTION",
        accessor: "so_description",
        show: true,
      },
      {
        Header: "INTERNAL DESCRIPTION",
        accessor: "internal_description",
        show: true,
      },
      {
        Header: "ACCOUNT EXCLUSIVE PRODUCT",
        accessor: "account_exclusive_product",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      },
      {
        Header: "HIDDEN FROM SO INVOICE",
        accessor: "hidden_from_so_invoice",
        show: true,
        Filter: TableRowsFilter,
        filter: "multiSelect",
      }

    ],
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const role_data = useFeatureFlagQuery({
    onCompleted: (data) => {
      setCDPRole(data.feature_flag.map((feat) => feat.feature));
    },
  });

  const { data, loading, error } = useProductSkuDataQuery({
  });

  if (loading) {
    return (
      <>
        <div
          className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
        >
          <div className="border-b border-gray-200 rounded-t-md">
            <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
              {reportsTab.map((tab) => {
                return (
                  <div
                    onClick={() => {
                      navigate(tab.url);
                    }}
                    className={`${
                      tab.active
                        ? "text-orange-500"
                        : "text-white hover:scale-105 transition-all"
                    } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="mx-6 mt-4">
          <Loader />
        </div>
      </>
    );
  }
  if (error) {
    return <i>Error</i>;
  }
  if (!data) {
    return <i>SKU data not available</i>;
  }

  const Skulines = data.product_sku_data;

  const filteredData = Skulines.filter((rep) => {
    if (!deliverySearch) {
      return true;
    }

    return `${rep.code} ${rep.name} ${rep.charge_type} ${rep.usd} ${rep.eur}  ${rep.apac_inr}  ${rep.apac_usd} ${rep.gbp} ${rep.qar} 
     ${rep.is_active} ${rep.bu} ${rep.product_group} ${rep.product_sub_group} ${rep.description} ${rep.is_deprecated} ${rep.sku_type}
     ${rep.product_sub_group_category} ${rep.account_exclusive_product} ${rep.display_unit} ${rep.hidden_from_so_invoice}
     `
      .toLowerCase()
      .includes(deliverySearch.toLowerCase());
  }).map((rep) => {
    return {
      code: rep.code || "--",
      name: rep.name || "--",
      charge_type: rep.charge_type || "--",
      display_unit: rep.display_unit || "--",
      account_exclusive_product: rep.account_exclusive_product ? "Yes" : "No",
      description: rep.description || "--",
      usd: rep.usd || "--",
      apac_inr: rep.apac_inr || "--",
      gbp: rep.gbp || "--",
      eur: rep.eur || "--",
      apac_usd: rep.apac_usd || "--",
      qar: rep.qar || "--",
      active: rep.is_active ? "Yes" : "No", 
      deprecated: rep.is_deprecated ? "Yes" : "No",
      internal_description: rep.description || "--",
      bu: rep.bu || "--",
      product_group: rep.product_group || "--",
      product_sub_group: rep.product_sub_group || "--",
      product_sub_group_category: rep.product_sub_group_category || "--",
      so_description: rep.so_description || "--" ,
      sku_type: rep.sku_type || "--",
      hidden_from_so_invoice: rep.hidden_from_so_invoice ? "Yes" : "No"
    };
  });
console.log(filteredData,' is the filtered data')
  

  const exportHeaders = [
    { label: "Code", key: "code" },
    { label: "Name", key: "name" },
    { label: "Charge Type", key: "charge_type" },
    { label: "List Price", key: "list_price" },
    { label: "Active", key: "active" },
    { label: "BU", key: "bu" },
    { label: "Group", key: "product_group" },
    { label: "Sub Group", key: "product_sub_group" },
    { label: "Sub Group Category", key: "product_sub_group_category" },
    { label: "Internal Description", key: "description" },
    { label: "SO Description", key: "so_description" },
    { label: "USD", key: "usd" },
    { label: "INR", key: "apac_inr" },
    { label: "APAC USD", key: "apac_usd" },
    { label: "EUR", key: "eur" },
    { label: "QAR", key: "qar" },
    { label: "GBP", key: "gbp" },
    { label: "Sku Type", key: "sku_type" }

  ];


  return (
    <ErrorBoundary>
      <div
        className={`hidden sm:block sticky top-14 z-20 bg-gradient-to-r from-gradientBlue via-gradientBlue to-gradientPurple w-full`}
      >
        <div className="border-b border-gray-200 rounded-t-md">
          <nav className="-mb-px px-6 py-1 flex space-x-8" aria-label="Tabs">
            {reportsTab.map((tab) => {
              return (
                <div
                  onClick={() => {
                    navigate(tab.url);
                  }}
                  className={`${
                    tab.active
                      ? "text-orange-500"
                      : "text-white hover:scale-105 transition-all"
                  } whitespace-nowrap p-2 font-medium text-sm cursor-pointer`}
                >
                  {tab.label}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
      <h1 className="font-poppins font-bold text-xl text-gray-800 ml-2 mb-2 p-4">Product/Services SKUs</h1>
        <PageTable
          columns={columns}
          height={window.innerHeight - 245}
          setFromDate={null}
          setToDate={null}
          search={deliverySearch}
          setSearch={setDeliverySearch}
          rowData={filteredData}
          warning="Invoice Lines"
          exportHeaders={exportHeaders}
          fileName={`CDP_Product_SKUS${moment().format("YYYYMMDDHHmmss")}.csv`}
          placeholder="Search for customer Product SKUs"
          showColumnMenu={false}
          isSKU={true}
        />
    </ErrorBoundary>
  );
};

export default SKUReports;
